import { forEach } from 'lodash';
import Vue from 'vue';
import api from './api';
import axios from 'axios';
import authHeader from './auth-header';

export default class ContentService {
    //constructor called in main.js
    constructor(configuration) {
        this.$configuration = configuration;
    }

    getInventoryItemDeeplinkURL(entityId) {
        var baseHostUrl = this.$configuration.VUE_APP_BASE_URL;
        var deeplinkUrl = baseHostUrl + 'inventory/' + entityId;
        return deeplinkUrl;
    }

    getCustomers(fieldNames, includeSuppliers) {
        return api.get('content/customers?fields=' + fieldNames.join(',') + '&includeSuppliers=' + (includeSuppliers ? 'true' : 'false'));
    }

    getAllCustomerNames() {
        return api.get('content/customers/names');
    }

    getCustomersSlim() {
        return api.get('content/customers/slimlist');
    }

    getCustomerForId(entityId) {
        return api.get('content/customers/' + entityId);
    }

    deleteCustomer(entityId) {
        return api.delete('content/customers/' + entityId);
    }

    addNewCustomer(entity) {
        return api.post('content/customers', entity);
    }

    addComment(entityId, comment, entityType) {
        return api.post('content/comments/' + entityType + '/' + entityId, {
            commentText: comment,
        });
    }

    deleteComment(commentId, parentEntityType) {
        return api.delete('content/comments/' + parentEntityType + '/' + commentId);
    }

    getInventory() {
        return api.get('content/inventory');
    }

    addInventoryItem(entity) {
        return api.post('content/inventory', entity);
    }

    deleteInventoryItem(entityId) {
        return api.delete('content/inventory/' + entityId);
    }

    getInventoryItemForId(entityId) {
        return api.get('content/inventory/' + entityId);
    }

    getAllInventoryModuleTypes(){
        return api.get('content/inventory/moduletypes');
    }

    // ARTICLE MASTER DATA CRUD
    getArticleMasterData() {
        return api.get('content/articleMasterData');
    }

    addArticleMasterData(entity) {
        return api.post('content/articleMasterData', entity);
    }

    deleteArticleMasterData(entityId) {
        return api.delete('content/articleMasterData/' + entityId);
    }

    getArticleMasterDataForId(entityId) {
        return api.get('content/articleMasterData/' + entityId);
    }

    getArticlePricingTableForDays(formula, basePrice, days){
        return api.get('content/articleMasterData/pricingTable/' + formula + '/' + basePrice + '/' + days);
    }

    // LOCATIONS CRUD
    getLocations() {
        return api.get('content/locations');
    }

    addLocation(entity) {
        return api.post('content/locations', entity);
    }

    deleteLocation(entityId) {
        return api.delete('content/locations/' + entityId);
    }

    getLocationForId(entityId) {
        return api.get('content/locations/' + entityId);
    }

    getUsers() {
        return api.get('content/users');
    }

    getUsersSlim() {
        return api.get('content/users/active/slimlist');
    }

    createUser(username, firstname, lastname, email, password, roles, skills, mobileNumber) {
        return api.post('content/users/createuser', {
            username: username,
            firstname: firstname,
            lastname: lastname,
            email: email,
            password: password,
            rolesAsStrings: roles,
            skillsAsStrings: skills,
            mobileNumber: mobileNumber
        });
    }

    setUserPassword(username, password) {
        return api.post('auth/passwordreset', {
            username: username,
            password: password,
        });
    }

    archiveUser(userId, deleteFlags) {
        var paramString = '';
        if (deleteFlags && deleteFlags.length > 0) {
            paramString = '?';
            for (var i = 0; i < deleteFlags.length; i++) {
                paramString += deleteFlags[i] + '=true&';
            }
        }
        return api.delete('content/users/' + userId + paramString);
    }

    addAdvancePaymentForUser(userId, advancePaymentObject){
        return api.post('content/users/' + userId + '/advancePayment', advancePaymentObject);
    }

    deleteAdvancePaymentForUser(userId, advancePaymentObject){
        return api.delete('content/users/' + userId + '/advancePayment/' +  advancePaymentObject.id);
    }

    getUserForId(entityId) {
        return api.get('content/users/' + entityId);
    }

    getEvents(fieldNames, startDateString, endDateString) {
        return api.get(
            this.$configuration.REST_API_BASE_URL +
                'content/events?fields=' +
                fieldNames.join(',') +
                '&startDate=' +
                startDateString +
                '&endDate=' +
                endDateString
        );
    }

    getEventsForLocation(locationId) {
        return api.get(this.$configuration.REST_API_BASE_URL + 'content/events/location/' + locationId);
    }

    getEventsForDispo(startDate, endDate) {
        return api.get('content/events/dispo?startDate=' + startDate + '&endDate=' + endDate);
    }

    addEvent(entity) {
        return api.post('content/events', entity);
    }

    deleteEvent(entityId) {
        return api.delete('content/events/' + entityId);
    }

    getEventForId(entityId) {
        const eventTransformerPromise = new Promise((resolve, reject) => {
            api.get('content/events/' + entityId).then(
                (response) => {
                    //flatten moduleCheckConfig object so entries appear to be regular properties for the frontend (since entityAwareInput cannot deal with nested props)
                    const dataToFlatten = response.data;

                    // extract configuredCheckGroups property values
                    dataToFlatten["checkInventoryItemTypes"] = [];
                    dataToFlatten["configuredCheckGroups"] = {};
                    const moduleConfig = dataToFlatten["moduleCheckConfig"];
                    for (const [inventoryType, configuredCheckGroups] of Object.entries(moduleConfig)) {
                        dataToFlatten["checkInventoryItemTypes"].push(inventoryType);
                        dataToFlatten["configuredCheckGroups"][inventoryType] = {};
                        for (const [key, value] of Object.entries(moduleConfig[inventoryType])) {
                            dataToFlatten["configuredCheckGroups"][inventoryType][key] = {};
                        }
                    }

                    // traverse down as long as property is an object
                    if(dataToFlatten.hasOwnProperty("moduleCheckConfig")){
                        this.flattenMap(dataToFlatten, dataToFlatten["moduleCheckConfig"], "moduleCheckConfig");
                        // TODO delete dataToFlatten["moduleCheckConfig"];
                    }
                    response.data = dataToFlatten;
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
        return eventTransformerPromise;
    }

    /**
     * recursive function to flatten a nested map to flat property names
     * Used in Event entity to flatten the dynamic "moduleCheckConfig" property
     */
    flattenMap(rootObject, keyValue, currentPathString){
        if(typeof keyValue === 'object' && !Array.isArray(keyValue) ){
            // still drilling down, go to next level
            if(Object.keys(keyValue).length > 0){
                for (const [key, value] of Object.entries(keyValue)) {
                    if(value != null)
                        this.flattenMap(rootObject, value, currentPathString + "." + key);
                }
            }
        } else {
            // final level, now we can write the value with flattened name
            rootObject[currentPathString] = keyValue;
        }
    }

    deleteEventCheckGroup(entityId, inventoryItemType, checkGroupName){
        return api.delete('content/events/' + entityId + '/' + checkGroupName + '?inventoryType=' +  inventoryItemType);
    }

    addEventCheckGroup(entityId, inventoryItemType, checkGroupName){
        return api.post('content/events/' + entityId + '/' + checkGroupName + '?inventoryType=' +  inventoryItemType);
    }

    deleteEventCheckGroupsForInventoryType(entityId, inventoryItemType){
        return api.delete('content/events/' + entityId + '/groups?inventoryType=' +  inventoryItemType);
    }

    addEventCheckGroupsForInventoryType(entityId, inventoryItemType){
        return api.post('content/events/' + entityId + '/groups?inventoryType=' +  inventoryItemType);
    }

    getMeetingsForDispo(startDate, endDate) {
        return api.get('content/meetings/dispo?startDate=' + startDate + '&endDate=' + endDate);
    }

    getMeetings(fieldNames, startDate, endDate) {
        return api.get('content/meetings?fields=' + fieldNames.join(',') + '&startDate=' + startDate + '&endDate=' + endDate);
    }

    deleteMeeting(entityId) {
        return api.delete('content/meetings/' + entityId);
    }

    addMeeting(entity) {
        return api.post('content/meetings', entity);
    }

    getMeetingForId(entityId) {
        return api.get('content/meetings/' + entityId);
    }

    getMeetingICS(entityId) {
        return api.get(this.$configuration.REST_API_BASE_URL + 'content/meetings/' + entityId + '/ics').then((response) => {
            if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'meeting.ics');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                // BLOB FOR EXPLORER 11
                // eslint-disable-next-line
                const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'meeting.ics');
            }
        });
    }

    getTimeEntriesForCurrentUser(startDate, endDate) {
        return api.get('clockify/myTimeEntries?start=' + startDate + '&end=' + endDate);
    }

    getTimeEntriesFiltered(startDate, endDate, userNames) {
        return api.get('clockify/timeEntries?start=' + startDate + '&end=' + endDate + '&users=' + userNames);
    }

    getClockifyUsers() {
        return api.get('clockify/clockifyUsers');
    }

    downloadBusinessExcelReport(startDateString, endDateString, dateTypeString, componentRef) {
        //if this causes problems at some point, try https://github.com/kennethjiang/js-file-download
        let url = this.$configuration.REST_API_BASE_URL + 'reporting/financialReport';
        api.get(url, {
            responseType: 'blob',
            params: {
                startDate: startDateString,
                endDate: endDateString,
                dateType: dateTypeString,
            },
        }).then(
            function (response) {
                if (!window.navigator.msSaveOrOpenBlob) {
                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'report.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else {
                    // BLOB FOR EXPLORER 11
                    // eslint-disable-next-line
                    const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'report.xlsx');
                }
                componentRef.loadingSpinneModal = false;
            }.bind(componentRef)
        );
    }

    downloadSalaryExcelReport(reportMonth, reportYear, componentRef) {
        //if this causes problems at some point, try https://github.com/kennethjiang/js-file-download
        let url = this.$configuration.REST_API_BASE_URL + 'reporting/salaryReport';
        api.get(url, {
            responseType: 'blob',
            params: {
                startDate: reportYear + "-" + ( (reportMonth<10) ? "0":"") + reportMonth + "-01"
            },
        }).then(
            function (response) {
                if (!window.navigator.msSaveOrOpenBlob) {
                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'salary-report-'+reportMonth+'-'+reportYear+'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else {
                    // BLOB FOR EXPLORER 11
                    // eslint-disable-next-line
                    const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'salary-report-'+reportMonth+'-'+reportYear+'.xlsx');
                }
                componentRef.loadingSpinneModal = false;
            }.bind(componentRef)
        ).catch(function(error) {
            componentRef.loadingSpinneModal = false;
            alert("Ein Fehler trat auf beim Versuch den Report zu generieren: " + error.message);
            console.error(error);
        }.bind(componentRef));
    }

    downloadChecklistExcelForEvent(eventId, componentRef) {
        //if this causes problems at some point, try https://github.com/kennethjiang/js-file-download
        let url = this.$configuration.REST_API_BASE_URL + 'content/events/checklist/' + eventId;
        api.get(url, {
            responseType: 'blob'
        }).then(
            function (response) {
                if (!window.navigator.msSaveOrOpenBlob) {
                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'checklist-' + eventId + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else {
                    // BLOB FOR EXPLORER 11
                    // eslint-disable-next-line
                    const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), 'checklist-' + eventId + '.xlsx');
                }
            }.bind(componentRef)
        ).catch(function(error) {
            alert("Ein Fehler trat auf beim Versuch die Checkliste zu generieren: " + error.message);
            console.error(error);
        }.bind(componentRef));
    }

    downloadAttachment(contentPath) {
        if (contentPath.startsWith('http')) {
            const link = document.createElement('a');
            link.href = contentPath;
            link.setAttribute('download', contentPath.substring(contentPath.lastIndexOf('/') + 1));
            document.body.appendChild(link);
            link.click();
        } else {
            api.get(this.$configuration.REST_API_BASE_URL + contentPath, {
                responseType: 'blob',
            }).then((response) => {
                if (!window.navigator.msSaveOrOpenBlob) {
                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', contentPath.substring(contentPath.lastIndexOf('/') + 1));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else {
                    // BLOB FOR EXPLORER 11
                    // eslint-disable-next-line
                    const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), contentPath.substring(contentPath.lastIndexOf('/') + 1));
                }
            });
        }
    }

    clearAllBackendCaches(){
        return api.get('content/configuration/clearAllCaches');
    }

    getConfiguration() {
        return api.get('content/configuration');
    }

    getConfigurationEntries() {
        return api.get('content/configuration/entries');
    }

    getTaggedItems(tag) {
        return api.get('content/tagged?tag=' + tag);
    }

    getAllTags() {
        return api.get('content/tags');
    }

    deleteTag(tag) {
        return api.delete('content/tags/' + tag);
    }

    getSearchResults(query) {
        return api.get('content/search?query=' + query);
    }

    uploadArticleMasterDataCsvImport(file){
        let formData = new FormData();
        formData.append('file', file);
        return api.post('content/articleMasterData/import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: authHeader().Authorization,
            },
        });
    }

    uploadAttachment(files, commentText, entityId, entityType, attachmentCategory) {
        let formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('commentText', commentText);
        formData.append('category', attachmentCategory);
        return api.post('content/attachments/' + entityType + '/' + entityId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: authHeader().Authorization,
            },
        });
    }

    uploadBackupToRestore(file) {
        let formData = new FormData();
        formData.append('file', file);
        return api.post('administration/backups/restore', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: authHeader().Authorization,
            },
        });
    }

    createBackup() {
        return api.get('administration/backups/create');
    }

    loadBackupList() {
        return api.get('administration/backups');
    }

    downloadBackup(archiveName) {
        api.get(this.$configuration.REST_API_BASE_URL + 'administration/backups/' + archiveName, {
            responseType: 'arraybuffer',
        }).then(function (response) {
            let blob = new Blob([response.data], { type: 'application/gzip' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = archiveName;
            link.click();
        });
    }

    downloadCSVImportTemplate(){
        api.get(this.$configuration.REST_API_BASE_URL + 'content/articleMasterData/import/template', {
            responseType: 'arraybuffer',
        }).then(function (response) {
            let blob = new Blob([response.data], { type: 'text/csv' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "5meter_article_import_template.csv";
            link.click();
        });
    }

    deleteBackupFromServer(archiveName) {
        return api.delete('administration/backups/' + archiveName);
    }

    createTimeOffEntry(timeOffEntryModel) {
        return api.post('content/timeoffentries', timeOffEntryModel);
    }

    getTimeOffEntries(startDate, endDate) {
        return api.get('content/timeoffentries?StartDate=' + startDate + '&EndDate=' + endDate);
    }

    getTimeOffEntryForId(entityId) {
        return api.get('content/timeoffentries/' + entityId);
    }

    archiveTimeOffEntry(entityId) {
        return api.delete('content/timeoffentries/' + entityId);
    }

    loadTimeOffAggreatedSums(startDate, endDate) {
        return api.get('content/timeoffentries/aggreated?StartDate=' + startDate + '&EndDate=' + endDate);
    }

    loadTimeOffInfoByDate(startDate, endDate) {
        return api.get('content/timeoffentries/absences?StartDate=' + startDate + '&EndDate=' + endDate);
    }

    loadAuditLog() {
        return api.get('administration/auditlog');
    }

    updateEntityProperty(entityType, entityId, propertyName, newValue) {
        return api.post(
            'content/' + entityType + '/' + entityId,
            {
                propertyName: propertyName,
                value: newValue,
            },
            { headers: authHeader() }
        );
    }

    updateStartPageContent(entityId, newValue) {
        return api.post('content/users/' + entityId + '/startPageContent', {
            startPageContent: newValue,
        });
    }
    startPageContent;

    updateEntityPropertyWithToastMessage(entityType, entityId, propertyName, newValue, toastService) {
        this.updateEntityProperty(entityType, entityId, propertyName, newValue).then(
            // eslint-disable-next-line
            (response) => {
                toastService.toast('Wert für ' + propertyName + ' gespeichert', {
                    autoHideDelay: 1000,
                    noCloseButton: true,
                    variant: 'success',
                    solid: false,
                });
            },
            (error) => {
                var errorMessage = (error.response && error.response.data) || error.message || error.toString();
                // eslint-disable-next-line
                console.error(errorMessage);
                toastService.toast(errorMessage.message, {
                    title: 'Beim Speichern von ' + propertyName + ' trat ein Fehler auf',
                    autoHideDelay: 7000,
                    variant: 'danger',
                    solid: true,
                });
            }
        );
    }

    deleteAttachment(attachmentId, parentEntityType) {
        return api.delete('content/attachments/' + parentEntityType + '/' + attachmentId);
    }

    getLexofficeInvoicesForCustomer(customerId) {
        return api.get('lexoffice/vouchers/' + customerId);
    }

    getLexofficeInvoices() {
        return api.get('lexoffice/vouchers/');
    }

    getLexofficeVoucher(voucherNumber, forceSync) {
        let forceSyncParam = (forceSync === true)? '?forceSync=true' : '';
        return api.get('lexoffice/voucher/' + voucherNumber + forceSyncParam);
    }

    validateLexofficeVoucherNumber(voucherNumber) {
        return api.get('lexoffice/voucher/validateref/' + voucherNumber);
    }

    startLexofficeImport() {
        return api.get('lexoffice/contacts/import');
    }

    startLexofficeVoucherImport() {
        return api.get('lexoffice/vouchers/import');
    }

    createLexofficeQuotationForEvent(eventId){
        return api.get('lexoffice/quotation/' + eventId);   
    }

    // DISPO-403 text block management (wysiwyg content)
    getTextBlocks() {
        return api.get('textblocks');
    }

    getTextBlock(id) {
        return api.get('textblocks/' + id);
    }

    getTextBlockByName(referenceName){
        return api.get('textblocks/name/' + referenceName);
    }

    addTextBlock(ref, content) {
        let data = {
            referenceName: ref,
            content: content
        };
        return api.post('textblocks', data);
    }

    updateTextBlock(id, ref, content){
        let data = {
            referenceName: ref,
            content: content
        };
        return api.post('textblocks/' + id, data);
    }

    deleteTextBlock(id) {
        return api.delete('textblocks/' + id);
    }

    sendEventBriefingRequest(eventId, mailObject){
        return api.post('content/events/' + eventId + "/briefingMail", mailObject);
    }

    install() {
        let thisinstance = this;
        Object.defineProperty(Vue.prototype, '$contentService', {
            get() {
                return thisinstance;
            },
        });
    }
}
