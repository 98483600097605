import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';

import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-tiptap/lib/index.css';
Vue.use(ElementUI);
Vue.use(ElementTiptapPlugin, {
    lang: "de",
    spellcheck: true, // can be overwritten by editor prop
});


import VeeValidate from 'vee-validate';
import Vue2TouchEvents from 'vue2-touch-events';
import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule';
Vue.use(SchedulePlugin);
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZfcHVTRmFcWUxyXEo=');

Vue.use(Vue2TouchEvents);
Vue.use(VeeValidate);

import Vuex from 'vuex';
Vue.use(Vuex);

import setupInterceptors from './services/setupInterceptors';
setupInterceptors(store);

import 'bootstrap';
import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faHome,
    faBuilding,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faCheck,
    faMinusSquare,
    faSquare,
    faCheckSquare,
    faCalendar,
    faTimesCircle,
    faPlusCircle,
    faMinusCircle,
    faPen,
    faLock,
    faLockOpen,
    faComments,
    faFile,
    faExpand,
    faDownload,
    faTrash,
    faStar,
    faTrashAlt,
    faCalendarCheck,
    faArrowUp,
    faArrowDown,
    faTimes,
    faKey,
    faClock,
    faChevronLeft,
    faChevronRight,
    faTruck,
    faAddressCard,
    faInfoCircle,
    faTrophy,
    faCopyright,
    faHotel,
    faCogs,
    faSave,
    faUpload,
    faCommentDots,
    faExclamationTriangle,
    faUserCheck,
    faSearch,
    faSearchPlus,
    faExternalLinkAlt,
    faCopy,
    faChartLine,
    faFileExcel,
    faSync,
    faHistory,
    faArrowRight,
    faArrowLeft,
    faUmbrellaBeach,
    faMoneyBillTrendUp,
    faFileInvoiceDollar,
    faRotateRight,
    faCloudArrowDown,
    faX,
    faCircleCheck,
    faClipboardCheck,
    faPersonRays,
    faNewspaper,
    faDoorOpen,
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';
library.add(
    faHome,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faBuilding,
    faCheck,
    faMinusSquare,
    faCheckSquare,
    faCalendar,
    faTimesCircle,
    faPlusCircle,
    faMinusCircle,
    faSquare,
    faPen,
    faLock,
    faLockOpen,
    faComments,
    faFile,
    faExpand,
    faDownload,
    faTrash,
    faStar,
    faTimes,
    faKey,
    faChevronLeft,
    faChevronRight,
    faClock,
    faTrashAlt,
    faCalendarCheck,
    faArrowUp,
    faArrowDown,
    faTruck,
    faAddressCard,
    faInfoCircle,
    faTrophy,
    faCopyright,
    faHotel,
    faCogs,
    faCommentDots,
    faExclamationTriangle,
    faSave,
    faUpload,
    faUserCheck,
    faSearch,
    faExternalLinkAlt,
    faCopy,
    faSearchPlus,
    faChartLine,
    faFileExcel,
    faSync,
    faHistory,
    faArrowRight,
    faArrowLeft,
    faUmbrellaBeach,
    faMoneyBillTrendUp,
    faFileInvoiceDollar,
    faRotateRight,
    faCloudArrowDown,
    faX,
    faCircleCheck,
    faClipboardCheck,
    faPersonRays,
    faDoorOpen,
    faNewspaper,
    faEnvelope
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import VoerroTagsInput from '@voerro/vue-tagsinput';
Vue.component('tags-input', VoerroTagsInput);

// create global accessible configuration object and acces to other central objects like the initialized stompclient for websocket communication
const shared = {
    REST_API_BASE_URL: process.env.VUE_APP_REST_API_BASE_URL,
    BASE_URL: process.env.VUE_APP_REST_API_BASE_URL.substring(0, process.env.VUE_APP_REST_API_BASE_URL.length - 5),
    BASE_URL_WEBSOCKET: process.env.VUE_APP_WEBSOCKET_BASE_URL,
    VUE_APP_BASE_URL: process.env.VUE_APP_BASE_URL,
    stompClient: null,
    socketHeartBeatStatus: { editModeEnabled: false, focussedFieldId: null, username: null, activePageUrl: null, tabId: null, entityId: null },
};
shared.install = function () {
    Object.defineProperty(Vue.prototype, '$configuration', {
        get() {
            return shared;
        },
    });
};
Vue.use(shared);
import AuthService from './services/auth.service';
const authService = new AuthService(shared);
Vue.use(authService);

import ContentService from './services/content.service';
const contentService = new ContentService(shared);
Vue.use(contentService);

import OnoffToggle from 'vue-onoff-toggle';
Vue.use(OnoffToggle);

//Vue.config.performance = true;
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
